/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

window.$ = window.jQuery = require("jquery");
require("../bootstrap");
require("slick-carousel");
require("./sidebar");

$(window).on("scroll", function () {
    var scrollTop = $(this).scrollTop();
    if (scrollTop > 60) {
        $("body").addClass("scrolled");
    } else {
        $("body").removeClass("scrolled");
    }
});

$(function () {
    $("html, body").removeClass("is-loading");
    setTimeout(() => {
        $(".loading-box").fadeOut(300, function () {
            $(this).remove();
        });
    }, 100);

    if ($("#testimonials-slider-1").length > 0) {
        $("#testimonials-slider-1").slick();
    }

    if ($("#testimonials-slider-2").length > 0) {
        $("#testimonials-slider-2").slick({
            slidesToShow: 3,
            centerMode: true,
            responsive: [
                {
                    breakpoint: 767,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        dots: true,
                    },
                },
            ],
        });
    }
});

$(document).on("change", ".imgs-box input", function () {
    window.readURL(this);
});

window.readURL = function (input) {
    var img = $(input).closest(".img-item").find("img");
    if (input.files && input.files[0]) {
        var reader = new FileReader();

        reader.onload = function (e) {
            $(img).attr("src", e.target.result);
        };

        reader.readAsDataURL(input.files[0]);
    }
};
var i = 1;

window.addImage = function () {
    var html =
        '<div class="img-item img-' +
        i +
        '">' +
        '    <label style="cursor: pointer" >' +
        '        <input name="imgs[]" type="file" hidden>' +
        '        <img src="/img/img.svg" alt="">' +
        "    </label>" +
        "</div>";

    var item = $($(".imgs-box").prepend(html));
    item.find(".img-" + i + " input").click();
    i++;
};
